
import React from "react";
import { Routes, Route, } from "react-router-dom";  
import ClientLayout from "../components/Clients/AddClients/ClientLayout";
import PageLayout from "../components/Common/Layout/PageLayout";
import Dashboard from "../components/Dashboard/DashboardLayout";
import LoginPage from "../components/LoginPage/LoginPage";
import CollegeList from "../components/Master/College/CollegeList";
import CountryList from "../components/Master/Country/CountryList";
import DistrictList from "../components/Master/District/DistrictList";
import IntakeList from "../components/Master/Intake/IntakeList";
import NocList from "../components/Master/Noc/NocList";
import OfficeList from "../components/Master/Office/OfficeList";
import PnpList from "../components/Master/Pnp/PnpList";
import ProgramAdd from "../components/Master/Program/ProgramAdd";
import ProgramEdit from "../components/Master/Program/ProgramEdit";
import ProgramList from "../components/Master/Program/ProgramList";
import QualificationAdd from "../components/Master/Qualification/QualificationAdd";
import QualificationEdit from "../components/Master/Qualification/QualificationEdit";
import QualificationList from "../components/Master/Qualification/QualificationList";
import RefferalList from "../components/Master/Refferal/Refferal";
import RoleAdd from "../components/Master/Role/RoleAdd";
import RoleEdit from "../components/Master/Role/RoleEdit";
import RoleList from "../components/Master/Role/RoleList";
import UniversityAdd from "../components/Master/University/UniversityAdd";
import UniversityEdit from "../components/Master/University/UniversityEdit";
import UniversityList from "../components/Master/University/UniversityList";
import UserDetailsList from "../components/Master/UserDetails/UserDetails";
import VisaTypeAdd from "../components/Master/VisaType/VisaTypaAdd";
import VisaTypeEdit from "../components/Master/VisaType/VisaTypeEdit";
import VisaTypeList from "../components/Master/VisaType/VisaTypeList";
import RouteConstants from "../Constants/RouteConstans";
import AddCollege from "../components/Master/College/AddCollege";
import AddCountry from "../components/Master/Country/AddCountry";
import AddDistrict from "../components/Master/District/AddDistrict";
import AddIntake from "../components/Master/Intake/AddIntake";
import AddNoc from "../components/Master/Noc/AddNoc";
import AddOffice from "../components/Master/Office/AddOffice";
import AddPnp from "../components/Master/Pnp/AddPnp";
import EditCollege from "../components/Master/College/EditCollege";
import EditCountry from "../components/Master/Country/EditCountry";
import EditDistrict from "../components/Master/District/EditDistrict";
import EditIntake from "../components/Master/Intake/EditIntake";
import EditNoc from "../components/Master/Noc/EditNoc";
import EditPnp from "../components/Master/Pnp/EditPnp";
import EditOffice from "../components/Master/Office/EditOffice";
import AddInfo from "../components/Master/AdditionalInfo/AddInfo";
import EditAddInfo from "../components/Master/AdditionalInfo/EditAddInfo";
import ListAddInfo from "../components/Master/AdditionalInfo/ListAddInfo";
import AddAppointment from "../components/Master/Appointments/AddAppointment";
import EditAppointment from "../components/Master/Appointments/EditAppointment";
import ListAppointment from "../components/Master/Appointments/ListAppointment";
import AddDoc from "../components/Master/DocDetail/AddDoc";
import EditDoc from "../components/Master/DocDetail/EditDoc";
import ListDoc from "../components/Master/DocDetail/ListDoc";
import AddProspect from "../components/Master/ProspectStatus/AddProspect";
import ListProspect from "../components/Master/ProspectStatus/ListProspect";
import EditProspect from "../components/Master/ProspectStatus/EditProspect";
import AddDuration from "../components/Master/ProgramDuration/AddDuration";
import EditDuration from "../components/Master/ProgramDuration/EditDuration";
import ListDuration from "../components/Master/ProgramDuration/ListDuration";
import AddFlwDuration from "../components/Master/FollowUpDuration/AddFlwDuration";
import EditFlwDuration from "../components/Master/FollowUpDuration/EditFlwDuration";
import ListFlwDuration from "../components/Master/FollowUpDuration/ListFlwDuration";
import ApplicantListing from "../components/Clients/AddClients/ApplicantListing";
import ApplicantAssignList from "../components/Clients/ApplicantAssign/ApplicantAssignList";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import ApplicantStaffDashboard from "../components/ApplicantStaffDashboard/ApplicantStaffDashboard";
import UserAdd from "../components/Users/UserAdd";
import UserEdit from "../components/Users/UserEdit";
import UserList from "../components/Users/UserList";
import AssociatesFormLayout from "../components/TeleCaller/AssociatesFormLayout";
import ListTelecaller from "../components/Dashboard/TelecallerDashboard";
import ApplicantDetailPage from "../components/Clients/ApplicantDetailPage";
import CounselorReport from "../components/Master/Reports/CounselorReport";
import ClientList from "../components/Admin/ClientList";
import AddCampus from "../components/Master/Campus/AddCampus";
import EditCampus from "../components/Master/Campus/EditCampus";
import ListCampus from "../components/Master/Campus/ListCampus";
import UniquestatusReport from "../components/Master/Reports/UniquestatusReport";
import Target from "../components/Master/Target/Target";
import AddAppStaffStatus from "../components/Master/AppStaffStatus/AddAppStaffStatus";
import EditAppStaffStatus from "../components/Master/AppStaffStatus/EditAppStaffStatus";
import ListAppStaffStatus from "../components/Master/AppStaffStatus/ListAppStaffStatus";
import BdmDashboard from "../components/Dashboard/BdmDashboard";
import TeleCallerClientAdd from "../components/TeleCaller/TeleCallerClientAdd";
import AddLeadType from "../components/Master/LeadTypes/AddLeadType";
import EditLeadType from "../components/Master/LeadTypes/EditLeadType";
import ListLeadType from "../components/Master/LeadTypes/ListLeadType";
import Employee from "../components/Master/Reports/Employee";
import CounselorRegistration from "../components/Master/Reports/CounselorRegistration";
import UserBranch from "../components/Users/UsersBranch";
import CeoApplicantList from "../components/CEO/CeoApplicantList";
import CountryWise from "../components/Master/Reports/CountryWise";
import Uniquecountrywise from "../components/Master/Reports/Uniquecountrywise";
import FeeCoordinatorDashboard from "../components/Dashboard/FeeCoordinatorDashboard";
import CeoNotInterestedReport from "../components/Master/Reports/CeoNotInterestedReport";
import ApplicantList from "../components/Admin/ApplicantList";
import SearchAllApplicant from "../components/Clients/ApplicantAssign/SearchAllApplicant";
import Branchwise from "../components/Master/Reports/Branchwise";
import Branchtarget from "../components/Master/Reports/BranchTarget";
import Countrytarget from "../components/Master/Reports/CountryTarget";
import FeeStatus from "../components/Master/Reports/FeepaidStatus";
import AdminExportList from "../components/Admin/ExportList";
import FeePaidReport from "../components/Master/Reports/FeePaidReport";
import VisaApprovedReport from "../components/Master/Reports/VisaApprovedReport";
import BranchManagerListing from "../components/BranchManager/BranchManager";
import TravelCounselorApplicant from "../components/Travel/TravelCounselorApplicant";
import LoanCounselorApplicant from "../components/Loan/LoanCounselorApplicant";
import PdfForm from "../components/Common/PdfForm";
import TelecallerImportEditLayout from "../components/TeleCaller/TelecallerImportEditLayout";
import DailyReport from "../components/Common/DailyReport";
import DailyReportCEO from "../components/Common/DailyReportCEO";
import ListCounselorAssociates from "../components/Master/CounselorAssociates/ListCounselorAssociates";
import { userStore } from "../store/userStore";
import NotInterestedListing from "../components/Clients/NotInterestedListing";
import NotQualifiedListing from "../components/Clients/NotQualifiedListing";
import StudentConfirmation from "../components/Public/StudentConfirmation";
import ErrorPage from "../components/Common/ErrorPage";
import ProgramSuggestion from "../components/Common/ProgramSuggestion";
import TopPerformerScreen from "../components/Master/Reports/TopPerformerScreen";
import AssociateReport from "../components/Master/Reports/AssociateReport";
import FinishedClients from "../components/Common/FinishedClients";
import FinishedClientsVisa from "../components/Common/FinishedClientsVisaWise";
import ColorSheme from "../components/ColorScheme/ColorSheme";
import AdmissionFeeForm from "../components/AdmissionFeeForm/AdmissionFeeForm";
import ResetPassword from "../components/LoginPage/ResetPassword";
import AdmissionStaffMyClients from "../components/AdmissionStaff/AdmissionStaffMyClients";
import CeoNotQualifiedReport from "../components/Master/Reports/CeoNotQualifiedReport";
import SeniorProgramManagerUsers from "../components/Master/SeniorProgramManagerUsers/SeniorProgramManagerUsers";
import SPMUserlist from "../components/SeniorProgramManager/SPMUserlist";
import ProgramCoordinatorMyClient from "../components/ProgramCoordinator.js/ProgramCoordinatorMyClient";
import CountrywiseReport from "../components/Master/Reports/CountrywiseReport";
import SeniorProgramManagerDashboard from "../components/Dashboard/SeniorProgramManagerDashboard";
import MyClientsSeniorProgramManager from "../components/SeniorProgramManager/MyClientsSeniorProgramManager";
import TelecallerMyClientsList from "../components/TeleCaller/TelecallerMyClientsList";
import VisaStaffMyClients from "../components/VisaStaff/VisaStaffMyClients";
import DocumentStaffMyClient from "../components/DocumentStaff/DocumentStaffMyClient";
import FeeCoordinatorMyClients from "../components/FeeCoordinator/FeeCoordinatorMyClients";
import SopStaffMyClientsList from "../components/SopStaff/SopStaffMyClientsList";
import PendingProgramListing from "../components/ProgramCoordinator.js/PendingProgramListing";
import ApplicantListingRM from "../components/RegionalManager/ApplicantListRM";
import NotificationPage from "../components/Common/NotificationPage";
import Pgmsuggestionreport from "../components/Master/Reports/Pgmsuggestion";
import MyAssignedClientsSeniorProgramCoordinator from "../components/SeniorProgramManager/MyAssignedClientsSeniorProgramCoordinator";
import CounselorNotinterested from "../components/Master/Reports/CounselorNotinterested";
import CountryPerfomance from "../components/Master/Reports/CountryPerfomance";
import OnholdReport from "../components/Master/Reports/Onholdreport";
import Readytomark from "../components/Master/Reports/Readytomark";
import Walkin from "../components/Master/Walkin/Walkin";
import WalkinData from "../components/Master/Walkin/WalkinData";
import WalkinEditForm from "../components/Master/Walkin/WalkinEditForm";
import NotProceeding from "../components/Master/Reports/Notproceeding";
import Officevisa from "../components/Master/Reports/OfficevisaReport";
import Officefeepaid from "../components/Master/Reports/OfficeFeepaidReport";
import FinishedClientsOffice from "../components/Common/FinishedClientsOfficeWise";
import CommissionReport from "../components/Master/Accounts/CommissionReport";
import ClientExport from "../components/Master/Reports/ClientExport";
import CounselorScoreScreen from "../components/Master/CounselorScore/CounselorScoreScreen";
import UsaProgram from "../components/Master/UsaProgramReport/UsaProgram";
import UsaProgramLocation from "../components/Master/UsaProgramReport/UsaProgramLocation";
import UsaProgramCity from "../components/Master/UsaProgramReport/UsaProgramCity";
import UsaProgramLevel from "../components/Master/UsaProgramReport/UsaProgramLevel";
import Countrywisenew from "../components/Master/Reports/Countrywsienew";
import LogoutAllUser from "../components/Public/LogoutAllUser";
import CollegeMarketingMyClients from "../components/TeleCaller/CollegeMarketingMyClients";
import CountrywiseReportNew from "../components/Master/Reports/Countrywisereportnew/CountrywiseReport";
import EventsList from "../components/Master/Events/EventsList";
import DistributionReport from "../components/Master/Reports/DistributionReport/DistributionReport";
import CallCenterApplicant from "../components/CallCenter/CallCenterApplicant";
import SecondaryRefferalList from "../components/Master/RefferalSecondary.js/RefferalSecondary";
import AllFollowupPending from "../components/FollowupPending/AllFollowupPending";
import AdmissionReport from "../components/Master/Reports/AdmissionStaffReport/AdmissionReport";

const AppRoutes = () => {

  const store = userStore()

  return (

      <Routes>

        <Route exact path={RouteConstants.ROOT} element={ <LoginPage/> } />
        <Route exact path={RouteConstants.FORGOTPWD} element={ <ForgotPassword/> } />
        <Route exact path={RouteConstants.PDFFORM} element={ <PdfForm/> } />
        <Route exact path={RouteConstants.STUDENTCONFIRM} element={ <StudentConfirmation /> } />
        <Route exact path={RouteConstants.ERRORPAGE} element={ <ErrorPage /> } />

        <Route path={RouteConstants.ROOT} element={<PageLayout />}>
            <Route path={RouteConstants.DASHBOARD} element={ <Dashboard/> } />
            <Route path={RouteConstants.APPLICANTDASHBOARD} element={ <ApplicantStaffDashboard/> } />
            <Route path={RouteConstants.USERADD} element={ <UserAdd/> } />
            <Route path={RouteConstants.USEREDIT} element={ <UserEdit/> } />
            <Route path={RouteConstants.USERLIST} element={ <UserList/> } />
            <Route path={RouteConstants.COLLEGE} element={ <CollegeList/> } />
            <Route path={RouteConstants.COUTRY} element={ <CountryList/> } />
            <Route path={RouteConstants.NOC} element={ <NocList/> } />
            <Route path={RouteConstants.OFFICE} element={ <OfficeList/> } />
            <Route path={RouteConstants.PROGRAM} element={ <ProgramList/> } />
            <Route path={RouteConstants.QUALIFICATION} element={ <QualificationList/> } />
            <Route path={RouteConstants.VISATYPE} element={ <VisaTypeList/> } />
            <Route path={RouteConstants.DISTRICT} element={ <DistrictList/> } />
            <Route path={RouteConstants.INTAKE} element={ <IntakeList/> } />
            <Route path={RouteConstants.PNP} element={ <PnpList/> } />
            <Route path={RouteConstants.REFFERAL} element={ <RefferalList/> } />
            <Route path={RouteConstants.SECONDARYREFFERAL} element={ <SecondaryRefferalList /> } />
            <Route path={RouteConstants.ROLE} element={ <RoleList/> } />
            <Route path={RouteConstants.UNIVERSITY} element={ <UniversityList/> } />
            <Route path={RouteConstants.USERDETAILS} element={ <UserDetailsList/> } />
            <Route path={RouteConstants.ADDCLIENT} element={ <ClientLayout/> } />
            <Route path={RouteConstants.ADDPROGRAM} element={ <ProgramAdd/> } />
            <Route path={RouteConstants.ADDQUALIFICATION} element={ <QualificationAdd/> } />
            <Route path={RouteConstants.ADDROLE} element={ <RoleAdd/> } />
            <Route path={RouteConstants.ADDUNIVERSITY} element={ <UniversityAdd/> } />
            <Route path={RouteConstants.ADDVISATYPE} element={ <VisaTypeAdd/> } />
            <Route path={RouteConstants.EDITPROGRAM} element={ <ProgramEdit/> } />
            <Route path={RouteConstants.EDITQUALIFICATION} element={ <QualificationEdit/> } />
            <Route path={RouteConstants.EDITROLE} element={ <RoleEdit/> } />
            <Route path={RouteConstants.EDITUNIVERSITY} element={ <UniversityEdit/> } />
            <Route path={RouteConstants.EDITVISATYPE} element={ <VisaTypeEdit/> } />
            <Route path={RouteConstants.ADDCOLLEGE} element={ <AddCollege/> } />
            <Route path={RouteConstants.ADDCOUNTRY} element={ <AddCountry/> } />
            <Route path={RouteConstants.ADDDISTRICT} element={ <AddDistrict/> } />
            <Route path={RouteConstants.ADDINTAKE} element={ <AddIntake/> } />
            <Route path={RouteConstants.ADDNOC} element={ <AddNoc/> } />
            <Route path={RouteConstants.ADDOFFICE} element={ <AddOffice/> } />
            <Route path={RouteConstants.ADDPNP} element={ <AddPnp/> } />
            <Route path={RouteConstants.EDITCOLLEGE} element={ <EditCollege/> } />
            <Route path={RouteConstants.EDITCOUNTRY} element={ <EditCountry/> } />
            <Route path={RouteConstants.EDITDISTRICT} element={ <EditDistrict/> } />
            <Route path={RouteConstants.EDITINTAKE} element={ <EditIntake/> } />
            <Route path={RouteConstants.EDITNOC} element={ <EditNoc/> } />
            <Route path={RouteConstants.EDITOFFICE} element={ <EditOffice/> } />
            <Route path={RouteConstants.EDITPNP} element={ <EditPnp/> } />
            <Route path={RouteConstants.ADDINFO} element={ <AddInfo/> } />
            <Route path={RouteConstants.EDITINFO} element={ <EditAddInfo/> } />
            <Route path={RouteConstants.LISTINFO} element={ <ListAddInfo/> } />
            <Route path={RouteConstants.ADDAPPOINT} element={ <AddAppointment/> } />
            <Route path={RouteConstants.EDITAPPOINT} element={ <EditAppointment/> } />
            <Route path={RouteConstants.LISTAPPOINT} element={ <ListAppointment/> } />
            <Route path={RouteConstants.ADDDOC} element={ <AddDoc/> } />
            <Route path={RouteConstants.EDITDOC} element={ <EditDoc/> } />
            <Route path={RouteConstants.LISTDOC} element={ <ListDoc/> } />
            <Route path={RouteConstants.ADDPROSPECT} element={ <AddProspect/> } />
            <Route path={RouteConstants.LISTPROSPECT} element={ <ListProspect/> } />
            <Route path={RouteConstants.EDITPROSPECT} element={ <EditProspect/> } />
            <Route path={RouteConstants.ADDPGDURATION} element={ <AddDuration/> } />
            <Route path={RouteConstants.EDITPGDURATION} element={ <EditDuration/> } />
            <Route path={RouteConstants.LISTPGDURATION} element={ <ListDuration/> } />
            <Route path={RouteConstants.ADDFOLLOWDURATION} element={ <AddFlwDuration/> } />
            <Route path={RouteConstants.EDITFOLLOWDURATION} element={ <EditFlwDuration/> } />
            <Route path={RouteConstants.LISTFOLLOWDURATION} element={ <ListFlwDuration/> } />
            <Route path={RouteConstants.MARKREGISTRATIONMYCLIENTS} element={ <ApplicantListing/> } />
            <Route path={RouteConstants.APPLICANTASSIGNLIST} element={ <ApplicantAssignList/> } />
            <Route path={RouteConstants.ADDTELECALLER} element={ <AssociatesFormLayout/> } />
            <Route path={RouteConstants.LISTTELECALLER} element={ <ListTelecaller/> } />
            <Route path={RouteConstants.ADMISSIONFEEORM} element={ <AdmissionFeeForm /> } />
            <Route path={RouteConstants.APPLICANTDETAILPAGE} element={ <ApplicantDetailPage/> } />
            <Route path={RouteConstants.COUNSELORREPORT} element={ <CounselorReport/> } />
            <Route path={RouteConstants.CLIENTLIST} element={ <ClientList/> } />
            <Route path={RouteConstants.ADDCAMPUS} element={ <AddCampus/> } />
            <Route path={RouteConstants.EDITCAMPUS} element={ <EditCampus/> } />
            <Route path={RouteConstants.LISTCAMPUS} element={ <ListCampus/> } />
            <Route path={RouteConstants.UNIQUESTATUS} element={ <UniquestatusReport/> } />
            <Route path={RouteConstants.TARGET} element={ <Target/> } />
            <Route path={RouteConstants.ADDAPPSTAFFSTATUS} element={ <AddAppStaffStatus/> } />
            <Route path={RouteConstants.EDITAPPSTAFFSTATUS} element={ <EditAppStaffStatus/> } />
            <Route path={RouteConstants.LISTAPPSTAFFSTATUS} element={ <ListAppStaffStatus/> } />
            <Route path={RouteConstants.IMPORT} element={ <BdmDashboard/> } />
            <Route path={RouteConstants.CLIENTADD} element={ <TelecallerImportEditLayout/> } />
            <Route path={RouteConstants.ADDLEADTYPE} element={ <AddLeadType/> } />
            <Route path={RouteConstants.EDITLEADTYPE} element={ <EditLeadType/> } />
            <Route path={RouteConstants.LISTLEADTYPE} element={ <ListLeadType/> } />
            <Route path={RouteConstants.EMPLOYEE} element={ <Employee/> } />
            <Route path={RouteConstants.COUNSELOREGISTRATION} element={ <CounselorRegistration/> } />
            <Route path={RouteConstants.USERBRANCH} element={ <UserBranch /> } />
            <Route path={RouteConstants.USERSLIST} element={ <CeoApplicantList /> } />
            <Route path={RouteConstants.COUNTRYWISE} element={ <CountrywiseReport/> } />
            <Route path={RouteConstants.FEECOORD} element={ <FeeCoordinatorDashboard /> } />
            <Route path={RouteConstants.UNIQUECOUNTRYWISE} element={ <CountrywiseReport/> } />
            <Route path={RouteConstants.NOTINTREPORT} element={ <CeoNotInterestedReport/> } />
            <Route path={RouteConstants.NOTQUALREPORT} element={ <CeoNotQualifiedReport /> } />
            <Route path={RouteConstants.FEEPAID} element={ <FeePaidReport /> } />
            <Route path={RouteConstants.VISAAPPROVED} element={ <VisaApprovedReport /> } />
            <Route path={RouteConstants.LEADLIST} element={ <ApplicantList /> } />
            <Route path={RouteConstants.SEARCHCLIENTS} element={ <SearchAllApplicant /> } />
            <Route path={RouteConstants.BRANCHWISE} element={ < Branchwise/> } />
            <Route path={RouteConstants.BTARGET} element={ < Branchtarget/> } />
            <Route path={RouteConstants.CTARGET} element={ < Countrytarget/> } />
            <Route path={RouteConstants.FEESTATUS} element={ < FeeStatus/> } />
            <Route path={RouteConstants.EXPORTCLIENT} element={ <AdminExportList /> } />
            <Route path={RouteConstants.BMLIST} element={ <BranchManagerListing /> } />
            <Route path={RouteConstants.LOAN} element={ <LoanCounselorApplicant /> } />
            <Route path={RouteConstants.TRAVEL} element={ <TravelCounselorApplicant /> } />
            {
              (store?.user?.role === "CEO" || store?.user?.role === "BMANAGER") ?
              <Route path={RouteConstants.DAILYREPORT} element={ <DailyReportCEO /> } />:
              <Route path={RouteConstants.DAILYREPORT} element={ <DailyReport /> } />
            }
            <Route path={RouteConstants.COUNSELORASSOCIATES} element={ <ListCounselorAssociates /> } />
            <Route path={RouteConstants.NOTINTEREST} element={ <NotInterestedListing /> } />
            <Route path={RouteConstants.NOTQUALIFIED} element={ <NotQualifiedListing /> } />
            <Route path={RouteConstants.PROGRAMSUGGESTION} element={ <ProgramSuggestion /> } />
            <Route path={RouteConstants.TOPPERFORMER} element={ <TopPerformerScreen /> } />
            <Route path={RouteConstants.ASSOCIATEREPORT} element={ <AssociateReport /> } />
            <Route path={RouteConstants.FINISHEDCLIENTS} element={ <FinishedClients /> } />
            <Route path={RouteConstants.VISAFINISHEDCLIENTS} element={ <FinishedClientsVisa /> } />
            <Route path={RouteConstants.OFFICEFINISHEDCLIENTS} element={ <FinishedClientsOffice /> } />
            <Route path={RouteConstants.COLORS} element={ <ColorSheme /> } />
            <Route path={RouteConstants.RESETPASSWORD} element={ <ResetPassword /> } />
            <Route path={RouteConstants.ADMISSIONSTAFFMYCLIENTS} element={ <AdmissionStaffMyClients /> } />
            <Route path={RouteConstants.SENIORMANAGERPROGRAMMASTER} element={ <SeniorProgramManagerUsers /> } />
            <Route path={RouteConstants.SENIORMANAGERUSERLIST} element={ <SPMUserlist /> } />
            <Route path={RouteConstants.PROGRAMMYCLIENTS} element={ <ProgramCoordinatorMyClient /> } />
            <Route path={RouteConstants.SENIORPROGRAMMANAGERMYCLIENT} element={ <MyClientsSeniorProgramManager /> } />
            <Route path={RouteConstants.TELECALLERMYCLIENTS} element={ <TelecallerMyClientsList /> } />
            <Route path={RouteConstants.VISASTAFFMYCLIENTS} element={ <VisaStaffMyClients /> } />
            <Route path={RouteConstants.DOCUMENTSTAFFMYCLIENTS} element={ <DocumentStaffMyClient /> } />
            <Route path={RouteConstants.FEECOORDINATORMYCLIENTS} element={ <FeeCoordinatorMyClients /> } />
            <Route path={RouteConstants.SOPSTAFFMYCLIENTS} element={ <SopStaffMyClientsList /> } />
            <Route path={RouteConstants.PENDINGPROGRAMLIST} element={ <PendingProgramListing /> } />
            <Route path={RouteConstants.RMMYCLIENTS} element={ <ApplicantListingRM /> } />
            <Route path={RouteConstants.NOTIFICATIONS} element={ <NotificationPage /> } />
            <Route path={RouteConstants.ADMISSIONSTAFFREPORT} element={ <AdmissionReport /> } />
            <Route path={RouteConstants.PGMSUGGESTIONREPORT} element={ <Pgmsuggestionreport /> } />
            <Route path={RouteConstants.MYCLIENTSSENIORPROGRAMCOORDINATOR} element={ <MyAssignedClientsSeniorProgramCoordinator /> } />
            <Route path={RouteConstants.COUNSELORNIS} element={ <CounselorNotinterested/> } />
            <Route path={RouteConstants.COUNTRYPERFOMANCE} element={ <CountryPerfomance/> } />
            <Route path={RouteConstants.ONHOLDREPORT} element={ <OnholdReport/> } />
            <Route path={RouteConstants.READYTOMARK} element={ <Readytomark/> } />
            <Route path={RouteConstants.WALKIN} element={ <Walkin/> } />
            <Route path={RouteConstants.WALKINDATA} element={ <WalkinData/> } />
            <Route path={RouteConstants.WALKINEDITFORM} element={ <WalkinEditForm/> } />
            <Route path={RouteConstants.NOTPROCEEDING} element={ <NotProceeding/> } />
            <Route path={RouteConstants.OFFICEVISA} element={ <Officevisa/> } />
            <Route path={RouteConstants.OFFICEFEEPAID} element={ <Officefeepaid/> } />
            <Route path={RouteConstants.COMMISSIONREPORT} element={<CommissionReport/>} />
            <Route path={RouteConstants.CLIENTEXPORT} element={ <ClientExport/> } />
            <Route path={RouteConstants.COUNSELORSCORE} element={ <CounselorScoreScreen/> } />
            <Route path={RouteConstants.USAPROGRAM} element={ <UsaProgram/> } />
            <Route path={RouteConstants.USALOCATION} element={<UsaProgramLocation/>}/>
            <Route path={RouteConstants.USACITY} element={<UsaProgramCity/>}/>  
            <Route path={RouteConstants.USALEVEL} element={<UsaProgramLevel/>} />
            <Route path={RouteConstants.COUNTRYWISENEW} element={<CountrywiseReportNew/>} />
            <Route path={RouteConstants.LOGOUTALLUSER} element={<LogoutAllUser />} />
            <Route path={RouteConstants.COLLEGEMARKETING} element={<CollegeMarketingMyClients />} />
            <Route path={RouteConstants.EVENTLIST} element={<EventsList />} />
            <Route path={RouteConstants.DISTRIBUTIONREPORT} element={<DistributionReport />} />
            <Route path={RouteConstants.CALLCENTERAPPLICANT} element={<CallCenterApplicant />} />
            <Route path={RouteConstants.ALLFOLLOWUPPENDING} element={<AllFollowupPending />} />
	      </Route>
        
      </Routes>
  );
}

export default AppRoutes;
